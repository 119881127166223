<template>
    <v-row no-gutters>
      <v-col>
        <div id="data-list" v-if="status" >
        
          <customer-list v-if="(userData.role === 'PLSYR' || userData.role === 'FIN' ) && $vuetify.breakpoint.xsOnly" />
          <v-card
            tile
            :flat="$vuetify.breakpoint.mdAndDown"
            :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
          >
            <v-toolbar color="#312d4b" :flat="!$vuetify.breakpoint.smAndDown" :height="$vuetify.breakpoint.smAndDown ? 57 : 87">
              <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium">
                <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                  {{ icons.mdiWallet }}
                </v-icon>
               Müşteri Bakiye Listesi
              </v-toolbar-title>
  
              <v-spacer></v-spacer>  
            
            </v-toolbar>
  
            <!-- table -->
            <v-data-table
              v-model="selectedRows"
              :headers="tableColumns"
              :items="dataListTable"
              :options.sync="options"
              :items-per-page="15"
              fixed-header
              :height="dataTableHeight"
              :loading="loading"
            >
              <template #[`item.CUSTOMER`]="{ item }">
                <v-chip small label color="grey">
                  <span class="text--secondary"> {{ item.CUSTOMER }}</span>
                </v-chip>
              </template>            
              <template #[`item.TBAKIYE`]="{ item }">
                <span class="success--text text-caption font-weight-bold"> {{ item.TBAKIYE | currency }}</span>
              </template>
              <template #[`item.VDBAKIYE`]="{ item }">
                <span class="success--text text-caption font-weight-bold"> {{ item.VDBAKIYE | currency }}</span>
              </template>
           
            
            </v-data-table>
          </v-card>
        </div>
        <div v-else>
          <customer-list v-if="(userData.role === 'PLSYR' || userData.role === 'FIN' ) && $vuetify.breakpoint.xsOnly" />
          <v-alert text outlined class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption" type="warning">
            Lütfen yukarıdan bir müşteri seçin
          </v-alert>
        </div>
      </v-col>
    </v-row>
  </template>


<script>
import store from '@/store'
import {
  mdiCamera,
  mdiCameraOff,
  mdiClose,
  mdiCreditCard,
  mdiDelete,
  mdiDownload,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiPlusThick,
  mdiSend,
  mdiSquareEditOutline,
  mdiWallet
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import Vue from 'vue'

export default {  
  setup() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const status = ref(false)
    const sendData = ref({})
    onMounted(() => {
      if ((userData.role === 'PLSYR' || userData.role === 'FIN') && store.state.plasiyerCustomer === null) {
        status.value = false
      } else {
        status.value = true
        loading.value = true
        fetchDatas()
      }
    })

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        if (store.state.plasiyerCustomer) {
          status.value = true
          loading.value = true
          fetchDatas()
        }
        else if (store.state.plasiyerCustomer === null)
        {
          console.log('plasiyerCustomer NULL? = ' + store.state.plasiyerCustomer)
          dataListTable.value = []
          status.value = false
          
        }
      },
    )

    const dataListTable = ref([])
    const dataItem = ref({})
    let tableColumns
    if (userData.role == 'PLSYR') {
      tableColumns = [
        {
          text: 'Müşteri No',
          value: 'CUSTOMER',
          width: '150px',
          sortable: false,
        },
        {
          text: 'Müşteri Unvanı',
          value: 'NAME1',
          width: '250px',
          sortable: false,
        },
        {
          text: 'Şehir',
          value: 'CITY',
          width: '120px',
          sortable: false,
        },
        {
          text: 'Bakiye',
          value: 'TBAKIYE',
          width: '120px',    
         // align: 'left min-width-400',
          sortable: false,
        },
        {
          text: 'Vade',
          value: 'VDBAKIYE',
          width: '120px',    
          //align: 'left min-width-400',
          sortable: false,
        },
      ]    
    }


    const totalDataListTable = ref(0)
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const dataTotalLocal = ref([])
    const selectedRows = ref([])



    const fetchDatas = () => {
        const data = {
        method: 'getSaldeptCustBalanceList',      
      }
      store
        .dispatch('postMethod', data)
        .then(response => {
          if (response.error == 0) {            
            dataListTable.value = response.detail
            loading.value = false
          } else {
            loading.value = false
            Vue.swal({
              title: 'Hata',
              text: 'Bir Hata Oluştu',
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
          }
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message === "Cannot read properties of null (reading 'length')" ? 'Sistem Hatası' : e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }

    watch(() => {
      loading.value = true
      selectedRows.value = []
      fetchDatas()
    })

    return {
      sendData,
      dataItem,
      dataListTable,
      tableColumns,    
      totalDataListTable,
      loading,
      options,
      dataTotalLocal,
      selectedRows,
      fetchDatas,
      status,
      userData,
      // icons
      icons: {
        mdiCamera,
        mdiDelete,
        mdiCameraOff,
        mdiCreditCard,
        mdiEye,
        mdiFilter,
        mdiFilterOff,
        mdiPlusThick,
        mdiSquareEditOutline,
        mdiClose,
        mdiDownload,
        mdiSend,
        mdiWallet
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 48 : 87,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },
      json_fields: {
        ID: 'id',
        'Kart No': 'cc_no',

        Fiyat: {
          field: 'amount',
          callback: value => {
            return `${Number(value.slice(0, -1))}`
          },
        },
        'Taksit Sayısı': {
          field: 'installment_count',
          callback: value => {
            return value == 1 ? 'Tek Çekim' : value + 'Taksit'
          },
        },
        'İşlem Tarihi': {
          field: 'datetime',
          callback: value => {
            return `${value.slice(0, 16)}`
          },
        },
        Açıklama: 'ltext',
      },

      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
